import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCategories(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/categories`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addCategory(ctx, categoryData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/categories`, categoryData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateCategory(ctx, { id, categoryData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/categories/${id}`, categoryData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCategoryIamge(ctx,  id ) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/files/${id}?path=categories`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeCategory(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/categories/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
