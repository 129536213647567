<script>
import {computed} from "@vue/composition-api";

export default {
  name: "sectionList",
  props: {
    sections: {
      default: []
    }
  },
  components: {},

  setup(props, {emit}) {

    const getButtonsCount = computed(() => {
      return props.sections.length || 0;
    });

    const onEdit = (item) => {
      emit('edit', item)
    }

    const onAdd = () => {
      emit('add')
    }

    const onDelete = (id) => {
      emit('delete', id)
    }


    return {
      onEdit,
      onAdd,
      onDelete,
      getButtonsCount
    }

  },


}

</script>

<template>
  <div>
    <div class="d-flex mt-2 justify-content-end">
      <b-button-group class="w-100">
        <b-button variant="outline-primary" :disabled="getButtonsCount >= 6" @click="onAdd()">{{
            $t('add_section')
          }}
        </b-button>
        <b-button variant="primary">{{ getButtonsCount }}/6</b-button>
      </b-button-group>
    </div>

    <b-list-group flush v-if="sections">
      <b-list-group-item class="d-inline flex" v-for="(section, index) in sections" :key="index">
        <div class="d-inline"><strong class="text-caption">{{ section.title }}</strong></div>
        <div class="float-right">
          <b-button-group>
            <b-button v-b-toggle="['collapse-' + index]" variant="outline-secondary" class="border-0">
              <feather-icon icon="ListIcon"/>
            </b-button>
            <b-button @click="onEdit(section)" variant="outline-secondary" class="border-0">
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button @click="onDelete(section._id)" variant="outline-secondary" class="border-0">
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </b-button-group>

        </div>

        <!-- Elements to collapse -->
        <b-collapse :visible="false" :id="'collapse-' + index" class="mt-2">
          <b-card no-body class="px-1">
            <b-list-group flush>
              <b-list-group-item class="d-inline flex" v-for="(item, index) in section.items" :key="index">
                <div class="d-inline"><strong class="text-caption">{{ item.text }}</strong></div>
                <div class="float-right" v-if="section.type === 'links'" v-html="item.icon"></div>
                <img class="float-right" v-if="section.type === 'networks'" style="max-width: 26px" :src="item.image.src" :alt="item.text">
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-collapse>
      </b-list-group-item>

    </b-list-group>

  </div>
</template>

<style scoped lang="scss">

.list-group-item {
  background: transparent !important;
  padding-inline: 0 !important;
  font-size: 12px;
}

.list-group-item p {
  margin-bottom: 0;
}

.btn-outline-secondary {
  padding: 5px 8px;
  border: none !important;
}

</style>
