<script>
import { BButton, BFormInput } from "bootstrap-vue";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import {
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
} from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import i18n from "@/libs/i18n";

export default {
  name: "GEditColors",
  components: { BButton, BFormInput },
  setup() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const MODULE_KB_STORE_NAME = "kb-store";

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    const toast = useToast();
    const debounceTimer = ref(null);

    const colors = ref({
      main: {
        primaryColor: "",
        titleColor: "",
        textColor: "",
        subTextColor: "",
        placeholderColor: "",
        backgroundColor: "",
        standardColor: "",
        shadowColor: "",
        pageShadow: "",
        autofillColor: "",
        yellowColor: "",
        greenColor: "",
        btnActiveColor: "",
        negativeColor: "",
        positiveColor: "",
        header_background_color: "",
        header_btn_color: "",
        header_btn_background_color: "",
        header_btn_background_color_hover: "",
        sidebar_background_color: "",
        sidebar_btn_color: "",
        sidebar_btn_background_color: "",
        sidebar_btn_background_color_hover: "",
      },
      userProfile: {
        bgPrimary: "",
        bgSecondary: "",
        colorBorder: "",
        colorPrimary: "",
        colorPrimaryText: "",
        colorSecondary: "",
      },
    });
    const loading = ref(false);

    const editTemplate = computed(
      () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const updateLocalColors = () => {
      colors.value.main = editTemplate.value.templateData.main.colors;
      colors.value.userProfile =
          editTemplate.value.templateData.user_profile.colors;
    };


    onMounted(() => {
      updateLocalColors();
    });

    const updateTemplate = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          `${MODULE_APP_STORE_NAME}/updateTemplate`,
          {
            template: editTemplate.value,
            toast,
            path: 'templateData.main'
          }
        );

        showSuccessToast(toast, "Colors", i18n.t("colors_updated"));
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_colors"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const changePreview = (value) => {
      store.commit("kb-store/SWITCH_PREVIEW", value);
    };

    onBeforeUnmount(() => {
      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }
      changePreview("general");
    });


    const { refFormObserver, getValidationState, resetForm } = formValidation(
      () => {}
    );

    const updatePreview = () => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          main: {
            ...editTemplate.value.templateData.main,
            colors: colors.value.main
          },
          user_profile: {
            ...editTemplate.value.templateData.user_profile,
            colors: colors.value.userProfile
          }
        }
      };
      store.commit(`${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE`, updatedTemplate);
    };

    const onColorChange = ({ key, value, section }) => {
      colors.value[section][key] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 1000); // 5000 ms = 5 segundos
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.main");
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.user_profile");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      updateLocalColors()
    };

    return {
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      colors,
      loading,
      changePreview,
      onColorChange,
    };
  },
};
</script>

<template>
  <div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('main')" active @click="changePreview('general')">
          <div class="list-group list-group-flush">
            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Primary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.primaryColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'primaryColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Title Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.titleColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'titleColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Text Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.textColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'textColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Sub Text Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.subTextColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'subTextColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Placeholder Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.placeholderColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'placeholderColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Background Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.backgroundColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'backgroundColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Standard Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.standardColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'standardColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Shadow Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.shadowColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'shadowColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Page Shadow</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.pageShadow"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'pageShadow',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Autofill Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.autofillColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'autofillColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Yellow Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.yellowColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'yellowColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Green Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.greenColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'greenColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <!--      <a-->
            <!--          class="list-group-item border-0 list-group-item-action d-flex gap-3"-->
            <!--          aria-current="true"-->
            <!--      >-->
            <!--        <div class="d-flex gap-2 w-100 justify-content-between">-->
            <!--          <div>-->
            <!--            <h6 class="mt-1">White 01</h6>-->
            <!--          </div>-->
            <!--          <span class="color-box">-->
            <!--            <b-form-input-->
            <!--                type="color"-->
            <!--                v-model="editTemplate.templateData.main.colors['white-01']"-->
            <!--            />-->
            <!--          </span>-->
            <!--        </div>-->
            <!--      </a>-->
            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Button Active Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.btnActiveColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'btnActiveColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>
            
            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Negative Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.negativeColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'negativeColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>
            
            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Positive Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.main.positiveColor"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'positiveColor',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Header Background Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.header_background_color"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'header_background_color',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Header Button Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.header_btn_color"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'header_btn_color',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Header Button Background Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.header_btn_background_color"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'header_btn_background_color',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Header Button Background Hover Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.header_btn_background_color_hover"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'header_btn_background_color_hover',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>
            
            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Sidebar Background Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.sidebar_background_color"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'sidebar_background_color',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Sidebar Button Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.sidebar_btn_color"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'sidebar_btn_color',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Sidebar Button Background Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.sidebar_btn_background_color"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'sidebar_btn_background_color',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Sidebar Button Background Hover Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.main.sidebar_btn_background_color_hover"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'sidebar_btn_background_color_hover',
                          value: newValue,
                          section: 'main',
                        })
                    "
                  />
                </span>
              </div>
            </a>

          </div>
        </b-tab>

        <b-tab :title="$t('user_profile')" @click="changePreview('profile')">
          <div class="list-group list-group-flush">
            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Background Primary</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.userProfile.bgPrimary"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'bgPrimary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Background Secondary</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.userProfile.bgSecondary"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'bgSecondary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Border Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.userProfile.colorBorder"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorBorder',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Primary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.userProfile.colorPrimary"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorPrimary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Text. Primary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.userProfile.colorPrimaryText"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorPrimaryText',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
              class="list-group-item border-0 list-group-item-action d-flex gap-3"
              aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Secondary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                    type="color"
                    :value="colors.userProfile.colorSecondary"
                    @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorSecondary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <div class="row justify-content-end pt-4 pb-2">
      <div class="col-md-6">
        <b-button
          :disabled="loading"
          size="sm"
          class="w-100"
          variant="outline-primary"
          @click="resetData()"
        >
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>

      <div class="col-md-6">
        <b-button
          :disabled="loading"
          class="w-100"
          @click="updateTemplate"
          size="sm"
          variant="primary"
        >
          Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-group {
  max-height: 300px;
  overflow-y: scroll;
  width: 300px;
}

.color-box {
  width: 50px;
}
</style>
