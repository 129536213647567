<template>
  <div class="sidebar" :class="{ open: isOpen }">
    <div class="sidebar-header">
      <h2>{{ title }}</h2>
      <button @click="closeSidebar" class="close-btn">&times;</button>
    </div>
    <div class="sidebar-content">
      <slot>
        <!-- Contenido por defecto si no se proporciona nada -->
        <p>Este es el contenido por defecto del sidebar.</p>
      </slot>    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    title: {
      type: String,
      default: "Sidebar",
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeSidebar() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #333;
  z-index: 100000;
  overflow-y: auto;
}

.sidebar.open {
  right: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: white;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.sidebar-content {
  padding: 20px;
}
</style>
