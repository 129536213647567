<script>
import { BButton, BFormInput } from "bootstrap-vue";
import store from "@/store";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import whitelabelTemplatesKBAuthModule from "@/views/components/whitelabel-templates/KB/config/PageEdit/LoginAndRegister/store/authStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

export default {
  name: "EditAuth",
  components: { BButton, BFormInput },
  setup(props, { emit }) {
    const MODULE_APP_STORE_NAME_2 = "app-whitelabel-templates-kb-auth";
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME_2))
      store.registerModule(
        MODULE_APP_STORE_NAME_2,
        whitelabelTemplatesKBAuthModule
      );

    const isAuthLoginModalModify = ref(false);
    const isAuthRegisterModalModify = ref(false);

    const setAuthLoginModal = (flag) => {
      isAuthRegisterModalModify.value = flag;
      isAuthLoginModalModify.value = true;
      store.commit("app-whitelabel-templates-kb-auth/SHOW_LOGIN_MODAL");
    };

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", { item, action });
    };

    onMounted(() => {
      setAuthLoginModal(true);
    });

    return {
      prepareUpdateOrCreate,
    };
  },
};
</script>

<template>
  <b-button size="md" @click="prepareUpdateOrCreate(null, 'edit')" class="w-100"
    >Login Edit</b-button
  >
</template>

<style scoped lang="scss"></style>
