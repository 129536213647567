<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import axios from "@/libs/axios";
import i18n from "@/libs/i18n";
import { RequestBuilder } from "@core/utils/requestBuilder";
import adsStoreModule from "@/views/components/whitelabel-templates/KB/config/PageEdit/AdsManagement/store/adsStoreModule";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";

export default {
  name: "index",
  components: {
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "ads",
        visible: false,
      }),
    },
  },

  setup(props, { emit }) {
    const MODULE_KB_STORE_NAME = "kb-store";
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const ADS_KB_STORE_MODULE_NAME = "kb-ads-store";

    // Register modules if not already registered
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(ADS_KB_STORE_MODULE_NAME))
      store.registerModule(ADS_KB_STORE_MODULE_NAME, adsStoreModule);
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    const ad = ref(null);
    const loading = ref(false);
    const images = ref([]);
    const rBuild = RequestBuilder();
    const toast = useToast();

    // Computed properties
    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );
    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetAd();
      if (props.options.itemsSelected) ad.value = props.options.itemsSelected;
      updatePreview();
    });

    const resetAd = () => {
      ad.value = {
        href: "",
        image: "",
      };
    };

    const resetData = () => {
      store.commit(
        "app-whitelabel-templates/RESET_EDIT_TEMPLATE",
        "templateData.ads"
      );
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const updatePreview = (value) => {
      const template = editTemplate.value;
      const adM = value || ad.value;
      const index = template.templateData.ads.findIndex(
        (obj) => obj._id === adM._id
      );
      if (index !== -1)
        store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", {
          ...template,
          templateData: {
            ...template.templateData,
            ads: template.templateData.ads.map((ad, i) =>
              i === index ? adM : ad
            ),
          },
        });
    };

    const onSubmit = async () => {
      if (isEditMode.value) {
        await updateAd();
      } else {
        await createAd();
      }
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
          "app-whitelabel-templates/updateTemplate",
          {
            template: editTemplate.value,
            toast,
            path: "templateData.ads",
          }
        );
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_template"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createAd = async () => {
      loading.value = true;
      ad.value.whitelabelId =
        store.state.whitelabelCurrencyNabvar.whitelabel._id;
      ad.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
          "kb-ads-store/createAd",
          rBuild.clean(ad.value)
        );

        showSuccessToast(toast, "Ads", i18n.t("ad_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("error_creating_update_ad"),
            text: axiosErrorHandle(error),
            icon: "EditIcon",
            variant: "error",
          },
        });
      } finally {
        loading.value = false;
      }
    };

    const updateAd = async () => {
      loading.value = true;

      try {
        const response = await store.dispatch("kb-ads-store/updateAd", {
          id: ad.value._id,
          adsData: rBuild.clean(ad.value),
        });
        showSuccessToast(toast, "Ads", i18n.t("ad_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("error_creating_update_ad"),
            text: axiosErrorHandle(error),
            icon: "EditIcon",
            variant: "error",
          },
        });
      } finally {
        loading.value = false;
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        ad.value.image = image || "";
        updatePreview();
      }
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      () => {}
    );

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      updateAd,
      createAd,
      ad,
      images,
      errorInLoadImage,
      loading,
      onUploadedImage,
    };
  },
};
</script>

<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        v-if="ad"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-list-group-item class="px-0" style="background: none">
          <validation-provider
            class="w-100"
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group label="name" label-for="a-name">
              <template #label>{{ $t("labels.name") }}</template>
              <b-form-input
                id="a-name"
                type="text"
                v-model="ad.name"
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">
                {{ validationContext.errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            class="w-100"
            #default="validationContext"
            name="link"
            rules="required"
          >
            <b-form-group label="name" label-for="h-name">
              <template #label>{{ $t("labels.link") }}</template>
              <b-form-input
                id="a-href"
                type="text"
                v-model="ad.href"
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">
                {{ validationContext.errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Image" label-for="ads-image">
            <span>563x243</span>
            <image-field-form
              :single-button="true"
              :text="$t('labels.image')"
              class="w-100 mt-1"
              path="ads"
              @uploadedImage="onUploadedImage"
              @error="errorInLoadImage"
              :size-validation="'563x243'"
              :images="images"
            >
            </image-field-form>
            <img
              style="max-width: 100%"
              v-if="ad.image"
              :src="ad.image"
              alt="Uploaded Image"
              class="mt-1 rounded"
            />
          </b-form-group>
        </b-list-group-item>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
            :disabled="loading || invalid"
            size="md"
            type="submit"
            class="w-100"
            variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<style scoped lang="scss"></style>
