<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref, onMounted } from "@vue/composition-api";
import store from "@/store";
import axios from "@/libs/axios";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import { svgs } from "@/views/components/whitelabel-templates/KB/assets/svgs";
import formValidation from "@core/comp-functions/forms/form-validation";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "LinkForm",
  components: {
    IconDropdown,
    BRow,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCol,
    vSelect,
  },
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: Object,
      default: () => ({
        href: "",
        title: "",
        position: "",
        buttonType: "",
        children: [],
        svgData: "",
        hoverColor: "",
      }),
    },
  },
  setup(props, { emit }) {
    const options = ref(svgs);
    const products = ref([]);
    const lobbies = ref([]);
    const productSelected = ref({});
    const lobbySelected = ref({});
    const backURL = ref(BACK_URL);
    const formValid = ref(false);

    const fetchData = async () => {
      try {
        const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client;
        const [productsResponse, lobbiesResponse] = await Promise.all([
          axios.get(`${backURL.value}/clients/availProd/${clientId}`),
          axios.get(`${backURL.value}/lobbies`, {
            params: {
              whitelabelId,
              templateId: props.template._id
            }
          }),
        ]);

        products.value = productsResponse.data.map(({ product }) => ({
          productId: product.productId,
          name: product.name,
        }));
        lobbies.value = lobbiesResponse.data.lobbys;
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    const updateLinkDetails = (val, type) => {
      if (val.position !== undefined) {
        props.link.position = val.position;
      } else if (type === "lobby") {
        lobbySelected.value = val;
        props.link.id = val._id;
        props.link.title = val.name;
        props.link.href = `/lobby?id=${val._id}`;
        props.link.position = val.position || 0;
      } else if (type === "product") {
        productSelected.value = val;
        props.link.id = val.productId;
        props.link.title = val.name;
        props.link.href = `/product/${val.productId}`;
      }
    };

    const lobby = computed({
      get: () => lobbySelected.value,
      set: (val) => {
        updateLinkDetails(val, "lobby");
      },
    });

    const product = computed({
      get: () => productSelected.value,
      set: (val) => {
        updateLinkDetails(val, "product");
      },
    });

    const resetSelections = () => {
      productSelected.value = { position: 0 };
      lobbySelected.value = { position: 0 };
    };

    const onSubmit = () => {
      if (props.settings.action === "edit") {
        emit("update");
      } else {
        emit("save");
      }
    };

    const onCancel = () => {
      resetSelections();
      emit("reset");
    };

    const linkButtonHandler = () => {
      emit(props.settings.action === "edit" ? "update" : "save");
    };

    const onIconSelected = (icon) => {
      props.link.svgData = icon.value;
      emit("iconSelected");
    };

    const { refLinkFormObserver, getValidationState, resetForm } =
      formValidation(() => {});
    onMounted(fetchData);

    return {
      options,
      products,
      lobbies,
      formValid,
      product,
      lobby,
      onCancel,
      linkButtonHandler,
      onIconSelected,
      refLinkFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
    };
  },
};
</script>

<template>
  <validation-observer
    ref="refLinkFormObserver"
    #default="{ handleSubmit, invalid }"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-list-group-item style="background: none" class="px-0">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="$t('button_type')" label-for="type">
              <v-select
                v-model="link.buttonType"
                label="text"
                :options="['link', 'product', 'lobby']"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <template v-if="link.buttonType === 'product'">
          <b-row>
            <validation-provider
              #default="validationContext"
              name="product"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group
                  :label="$t('labels.products')"
                  label-for="products"
                >
                  <v-select
                    v-model="product"
                    :options="products"
                    label="name"
                    :state="getValidationState(validationContext)"
                    required
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>
        </template>

        <template v-if="link.buttonType === 'lobby'">
          <b-row>
            <validation-provider
              #default="validationContext"
              name="lobby"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group :label="$t('lobby')" label-for="lobby">
                  <v-select
                    v-model="lobby"
                    :options="lobbies"
                    label="name"
                    track-by="name"
                    :state="getValidationState(validationContext)"
                    required
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="position"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group
                  :label="$t('position')"
                  label-for="lobby-position"
                >
                  <b-form-input
                    id="lobby-position"
                    v-model="lobby.position"
                    type="number"
                    min="0"
                    :state="getValidationState(validationContext)"
                    required
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>
        </template>

        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('labels.hovercolor')"
              label-for="h-backgroundColor"
            >
              <b-form-input
                id="h-backgroundColor"
                type="color"
                v-model="link.hoverColor"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <validation-provider
            #default="validationContext"
            name="title"
            rules="required"
            class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('title')" label-for="title">
                <b-form-input
                  id="title"
                  v-model="link.title"
                  :state="getValidationState(validationContext)"
                  required
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
            #default="validationContext"
            name="url"
            rules="required"
            class="w-100"
          >
            <b-col cols="12">
              <b-form-group label="url" label-for="url">
                <b-form-input
                  id="url"
                  v-model="link.href"
                  :state="getValidationState(validationContext)"
                  :disabled="
                    link.buttonType === 'product' || link.buttonType === 'lobby'
                  "
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
            #default="validationContext"
            name="position"
            rules="required"
            class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('position')" label-for="position">
                <b-form-input
                  id="position"
                  v-model="link.position"
                  type="number"
                  min="0"
                  :state="getValidationState(validationContext)"
                  required
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label="Icon" label-for="icon">
              <b-input-group>
                <IconDropdown
                  :icons="options"
                  :selected-icon-prop="link.svgData"
                  @icon-selected="onIconSelected($event, link)"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <div class="mt-4 d-flex flex-row" style="gap: 5px">
        <b-button
          :disabled="invalid || !link.buttonType"
          type="submit"
          variant="primary"
        >
          {{
            settings.action === "edit"
              ? $t("buttons.update_and_apply")
              : $t("buttons.create_and_apply")
          }}
        </b-button>
        <b-button @click="onCancel" variant="secondary">{{
          $t("buttons.cancel")
        }}</b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<style scoped lang="scss"></style>
