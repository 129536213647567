<script>
import {BButton, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {computed, ref, watch} from "@vue/composition-api";
import ItemList from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/itemForm.vue";
import SectionList from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/sectionList.vue";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import store from "@/store";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "SectionForm",
  components: {
    SectionList,
    ItemForm, ItemList, BRow, BButton, BFormInput, BFormInvalidFeedback, BFormGroup, BCol, vSelect
  },

  props: {
    settings: {
      status: 'list',
      action: 'edit'
    },

    section: {
      type: Object,
      default: () => ({
        title: '',
        items: [],
        columns: [],
        type: '',
        position: '0',
      })
    }
  },

  setup(props, {emit}) {
    const types = ref([{value: 'links', text: 'Link'}, {value: 'networks', text: 'Networks'}])
    const item = ref(null)
    const itemSettings = ref({
      status: 'list',
      action: 'edit'
    })
    const uuidGen = UUIDGenerator()

    const backURL = ref(BACK_URL)
    const formValid = ref(false)

    const showList = () => {
      itemSettings.value.status = 'list'
    }

    const showForm = () => {
      itemSettings.value.status = 'form'
    }

    const onCancel = () => {
      emit('reset')
    }

    const sectionItemHandler = () => {
      if (!formValid.value) {
        return
      }

      if (props.settings.action === 'edit') {
        emit('update')
      } else {
        emit('save', props.section)
      }
    }


    watch(() => props.section, () => {
      checkForm()
    }, {deep: true})


    const checkForm = () => {
      const required = ['title', 'position']
      formValid.value = true
      required.forEach(key => {
        if (!props.section[key]) {
          formValid.value = false
        }
      })
    }

    const onDeleteItem = (id) => {
      props.section.items = props.section.items.filter(item => item._id !== id);
    }

    const enableEditMode = () => {
      itemSettings.value.action = 'edit'
    }

    const onEditItem = (value) => {
      showForm()
      enableEditMode()
      item.value = value
    }

    const enableAddMode = () => {
      itemSettings.value.action = 'add'
    }

    const resetItem = () => {
      item.value = {
        position: '0',
        text: '',
        icon: '',
        href: '',
        image: {
          src: '',
          alt: ''
        }
      }
    }

    const onAddItem = () => {
      showForm()
      enableAddMode()
      resetItem()
    }

    const saveItem = (value) => {

      item.value = {
        _id: uuidGen.generate(),
        ...value
      }
      props.section.items.push(item.value);

      resetAll()
      sortItems()
    }

    const sortItems = () => {
      const items = props.section.items
      items.sort((a, b) => Number(a.position) - Number(b.position));
      props.section.items = items
    }

    const resetAll = () => {
      resetItem()
      showList()
    }

    const updateLink = (val) => {
      props.section.items = props.section.items.map(i => {
        if (i._id === item.value._id) {
          return item.value;
        }
        return i;
      });
      showList()
      resetAll()
      sortItems()
    }

    return {
      onCancel,
      onDeleteItem,
      formValid,
      types,
      itemSettings,
      onAddItem,
      item,
      updateLink,
      saveItem,
      onEditItem,
      sectionItemHandler,
      resetAll
    }
  },

  created() {
  }
}
</script>

<template>
  <div v-if="section">

    <div class="mx-1">
      <h6>{{settings.action === 'edit' ? $t('Edit') : $t('tabs.add')}} {{ $t('section') }}</h6>
      <hr>
    </div>

    <div class="d-flex align-items-center">
      <b-col class="border-md-left" cols="12">
        <b-form-group label-for="type">
          <template #label>{{ $t("labels.type") }}</template>
          <b-form-select
              v-model="section.type"
              :disabled="itemSettings.status === 'form' || settings.action === 'edit'"
              label="text"
              :options="types"
          />
        </b-form-group>
      </b-col>
    </div>


    <b-col class="border-md-left" cols="12">
      <b-form-group label-for="title">
        <template #label>{{ $t("title") }}</template>
        <b-form-input
            id="title"
            :disabled="!section.type || itemSettings.status === 'form'"
            required
            type="text"
            v-model="section.title">

        </b-form-input>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group label-for="position">
        <template #label>{{ $t('position') }}</template>
        <b-form-input
            id="position"
            type="number"
            min="0"
            :disabled="!section.type  || itemSettings.status === 'form'"
            required
            v-model="section.position"/>
      </b-form-group>
    </b-col>


    <item-list @delete="onDeleteItem($event)"
               @add="onAddItem()"
               @edit="onEditItem($event)"
               :settings="itemSettings"
               v-if="itemSettings.status === 'list'"
               :items="section.items"></item-list>

    <item-form v-if="itemSettings.status === 'form'"
               @update="updateLink($event)"
               :settings="itemSettings" :item="item"
               :type="section.type"
               @reset="resetAll()"
               @save="saveItem($event)"></item-form>

    <div class="mt-1" v-if="itemSettings.status === 'list'">
      <div class="col">
        <b-button :disabled="!formValid || !section.type"
                  class="w-100 mt-1"
                  @click="sectionItemHandler"
                  variant="primary">
          {{ settings.action === 'edit' ? $t('buttons.edit') : $t('buttons.add') }} {{$t('section')}}
        </b-button>
      </div>
      <div class="col">
        <b-button class="w-100 mt-1" variant="secondary" @click="onCancel()">
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>