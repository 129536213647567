import axios from '@axios'

const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSidebars(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/sidebars`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchHeader(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/sidebars/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addSidebar(ctx, sidebarData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/sideBars`, sidebarData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateSidebar(ctx, {id, sidebarData}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/sideBars/${id}`, sidebarData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchHeaderIamge(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/files/${id}?path=sidebars`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeSidebar(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/sidebars/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
