<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group label="Desktop image">
          <span>802x1268</span>
          <image-field-form
            :single-button="true"
            :text="$t('labels.image')"
            class="w-100 mt-1"
            size-validation="802x1268"
            path="register"
            @error="errorInLoadImage"
            @uploadedImage="
              onUploadedImage($event, 'auth_modal_left_desktop_image')
            "
            :images="images"
          >
          </image-field-form>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Mobile image">
          <span>780x436</span>
          <image-field-form
            :single-button="true"
            :text="$t('labels.image')"
            class="w-100 mt-1"
            size-validation="780x436"
            path="register"
            @error="errorInLoadImage"
            @uploadedImage="
              onUploadedImage($event, 'auth_modal_left_mobile_image')
            "
            :images="images"
          >
          </image-field-form>
        </b-form-group>
      </b-col>

      <div class="p-2 d-flex justify-content-between">
        <b-button
          size="sm"
          @click="updateTemplate"
          variant="primary"
          :disabled="loading"
        >
          {{ loading ? "Guardando..." : "Guardar" }}
        </b-button>
        <b-button :disabled="loading" class="ml-1" size="sm" @click="resetData" variant="danger">
          Cancelar
        </b-button>
      </div>
    </b-row>
  </div>
</template>

<script>
import { computed, ref } from "@vue/composition-api";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import i18n from "@/libs/i18n";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";

export default {
  name: "RegisterForm",
  components: {
    ImageFieldForm,
  },
  setup() {
    const loading = ref(false);
    const images = ref([]);
    const toast = useToast();

    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedImage = (data, path) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        updatePreview(path, image);
      }
    };

    const updatePreview = (path, value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          register: {
            ...editTemplate.value.templateData.register,
            [path]: value || ""
          }
        }
      };

      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateTemplate = async () => {
      loading.value = true;
      try {
        const template = await store.dispatch(
          "app-whitelabel-templates/updateTemplate",
          {
            template: editTemplate.value,
            toast,
            path: 'templateData.register'
          }
        );

      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_template"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.register");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
    };

    return {
      images,
      loading,
      errorInLoadImage,
      editTemplate,
      resetData,
      updateTemplate,
      onUploadedImage,
    };
  },
};
</script>
