<script>
import {BFormCheckbox, BFormGroup, BFormInvalidFeedback} from "bootstrap-vue";
import {ValidationProvider} from "vee-validate";

export default {
  name: "authContent",
  components: {BFormCheckbox, BFormInvalidFeedback, ValidationProvider, BFormGroup},

  props: {
    auth: {
      default: {
        background_color: '',
        text_color: '',
        hide: false,
      }
    }
  },

  setup(props, {emit}) {

  }
}
</script>

<template>
  <div>
    <hr>
    <h5>Authentication</h5>
    <div class="row px-1 mt-2">
      <b-form-group class="w-100" label-for="background_color">
        <template #label>{{ $t("labels.backgroundcolor") }}</template>
        <b-input
            type="color"
            id="background_color"
            v-model="auth.background_color"
            label="background_color"
        />
      </b-form-group>


      <b-form-group class="w-100" label-for="text_color">
        <template #label>{{ $t("text_color") }}</template>
        <b-input
            type="color"
            id="text_color"
            v-model="auth.text_color"
            label="text_color"
        />
      </b-form-group>

    </div>
  </div>
</template>

<style scoped lang="scss">

</style>