<script>
import { BButton, BFormInput } from "bootstrap-vue";
import store from "@/store";
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "@vue/composition-api";
import whitelabelTemplatesKBAuthModule from "@/views/components/whitelabel-templates/KB/config/PageEdit/LoginAndRegister/store/authStoreModule";

export default {
  name: "EditAuth",
  components: { BButton, BFormInput },
  setup(props, { emit }) {
    const MODULE_APP_STORE_NAME_2 = "app-whitelabel-templates-kb-auth";
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME_2))
      store.registerModule(
        MODULE_APP_STORE_NAME_2,
        whitelabelTemplatesKBAuthModule
      );

    const isAuthLoginModalModify = ref(false);
    const isAuthRegisterModalModify = ref(false);

    const setAuthRegisterModal = (flag) => {
      isAuthRegisterModalModify.value = flag;
      isAuthLoginModalModify.value = false;
      store.commit("app-whitelabel-templates-kb-auth/SHOW_REGISTER_MODAL");
    };

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", { item, action });
    };

    onMounted(() => {
      setAuthRegisterModal(true);
    });

    return {
      prepareUpdateOrCreate,
    };
  },
};
</script>

<template>
  <b-button size="md" @click="prepareUpdateOrCreate(null, 'edit')" class="w-100"
    >Register Edit</b-button
  >
</template>

<style scoped lang="scss">
.list-group {
  max-height: 300px;
  overflow-y: scroll;
  width: 300px;
}

.color-box {
  width: 50px;
}
</style>
