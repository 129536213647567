import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAds(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/ads`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        createAd(ctx, adData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/ads`,  adData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateAd(ctx, { id, adsData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/ads/${id}`, adsData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeAd(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/ads/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
