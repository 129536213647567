var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":_vm.$t('main'),"active":""},on:{"click":function($event){return _vm.changePreview('general')}}},[_c('div',{staticClass:"list-group list-group-flush"},[_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Primary Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.primaryColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'primaryColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Title Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.titleColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'titleColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Text Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.textColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'textColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Sub Text Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.subTextColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'subTextColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Placeholder Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.placeholderColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'placeholderColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Background Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.backgroundColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'backgroundColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Standard Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.standardColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'standardColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Shadow Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.shadowColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'shadowColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Page Shadow")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.pageShadow},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'pageShadow',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Autofill Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.autofillColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'autofillColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Yellow Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.yellowColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'yellowColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Green Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.greenColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'greenColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Button Active Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.btnActiveColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'btnActiveColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Negative Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.negativeColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'negativeColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Positive Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.positiveColor},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'positiveColor',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Header Background Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.header_background_color},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'header_background_color',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Header Button Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.header_btn_color},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'header_btn_color',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Header Button Background Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.header_btn_background_color},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'header_btn_background_color',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Header Button Background Hover Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.header_btn_background_color_hover},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'header_btn_background_color_hover',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Sidebar Background Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.sidebar_background_color},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'sidebar_background_color',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Sidebar Button Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.sidebar_btn_color},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'sidebar_btn_color',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Sidebar Button Background Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.sidebar_btn_background_color},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'sidebar_btn_background_color',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Sidebar Button Background Hover Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.main.sidebar_btn_background_color_hover},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'sidebar_btn_background_color_hover',
                        value: newValue,
                        section: 'main',
                      }); }}})],1)])])])]),_c('b-tab',{attrs:{"title":_vm.$t('user_profile')},on:{"click":function($event){return _vm.changePreview('profile')}}},[_c('div',{staticClass:"list-group list-group-flush"},[_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Background Primary")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.bgPrimary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'bgPrimary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Background Secondary")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.bgSecondary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'bgSecondary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Border Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorBorder},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorBorder',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Primary Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorPrimary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorPrimary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Text. Primary Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorPrimaryText},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorPrimaryText',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Secondary Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorSecondary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorSecondary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])])])])],1)],1),_c('div',{staticClass:"row justify-content-end pt-4 pb-2"},[_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"w-100",attrs:{"disabled":_vm.loading,"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.resetData()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")])],1),_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"w-100",attrs:{"disabled":_vm.loading,"size":"sm","variant":"primary"},on:{"click":_vm.updateTemplate}},[_vm._v(" Guardar")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }