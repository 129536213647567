<script>
import store from "@/store";
import { BOverlay } from "bootstrap-vue";
import Sidebar from "@/views/components/whitelabel-templates/common/Sidebar.vue";
import PEditHeader from "@/views/components/whitelabel-templates/KB/config/PageEdit/HeaderManagement/index.vue";
import PEditMenu from "@/views/components/whitelabel-templates/KB/config/PageEdit/SidebarManagement/index.vue";
import HeaderList from "@/views/components/whitelabel-templates/KB/config/PageEdit/HeaderManagement/headerList.vue";
import PEditAds from "@/views/components/whitelabel-templates/KB/config/PageEdit/AdsManagement/index.vue";
import PECategory from "@/views/components/whitelabel-templates/KB/config/PageEdit/CategoryManagement/index.vue";
import AdsList from "@/views/components/whitelabel-templates/KB/config/PageEdit/AdsManagement/adsList.vue";
import CategoryList from "@/views/components/whitelabel-templates/KB/config/PageEdit/CategoryManagement/categoryList.vue";
import FooterList from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/footerList.vue";
import PEFooter from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/index.vue";
import SidebarList from "@/views/components/whitelabel-templates/KB/config/PageEdit/SidebarManagement/sidebarList.vue";
import PESidebar from "@/views/components/whitelabel-templates/KB/config/PageEdit/SidebarManagement/index.vue";
import PEditSidebar from "@/views/components/whitelabel-templates/KB/config/PageEdit/SidebarManagement/index.vue";
import PEditActionCard from "@/views/components/whitelabel-templates/KB/config/PageEdit/ActionCardManagement/index.vue";
import ActionCardsList from "@/views/components/whitelabel-templates/KB/config/PageEdit/ActionCardManagement/actionCardList.vue";
import Login from "@/views/components/whitelabel-templates/KB/config/PageEdit/LoginAndRegister/Login/index.vue";
import PEEditLogin from "@/views/components/whitelabel-templates/KB/config/PageEdit/LoginAndRegister/Login/LoginForm.vue";
import Register from "@/views/components/whitelabel-templates/KB/config/PageEdit/LoginAndRegister/Register/index.vue";
import PEEditRegister from "@/views/components/whitelabel-templates/KB/config/PageEdit/LoginAndRegister/Register/RegisterForm.vue";
import PEditLobby from "@/views/components/whitelabel-templates/KB/config/PageEdit/LobbyManagement/index.vue";
import LobbyList from "@/views/components/whitelabel-templates/KB/config/PageEdit/LobbyManagement/lobbyList.vue";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "FEdit",
  components: {
    Sidebar,
    LobbyList,
    BOverlay,
    ActionCardsList,
    SidebarList,
    FooterList,
    CategoryList,
    AdsList,
    HeaderList,
    PEditHeader,
    PEditMenu,
    PEditAds,
    PECategory,
    PESidebar,
    PEFooter,
    PEditActionCard,
    PEditSidebar,
    PEEditLogin,
    PEEditRegister,
    Login,
    Register,
    PEditLobby,
  },

  data() {
    return {
      sideBar: {
        action: "",
        itemsSelected: null,
        title: "",
        type: "",
        visible: false,
      },
      edit: [
        {
          name: "Header",
          collapsed: false,
        },
        { name: "Sidebar", collapsed: false },
        { name: "Ads", collapsed: false },
        { name: "Categories", collapsed: false },
        { name: "Action Cards", collapsed: false },
        { name: "Footer", collapsed: false },
        { name: "Login", collapsed: false },
        { name: "Register", collapsed: false },
        { name: "Lobby", collapsed: false },
      ],
    };
  },

  methods: {
    onUpdateOrCreate(value, type, title) {
      const currentlyExpanded = this.edit.findIndex((item) => item.collapsed);
      if (currentlyExpanded !== -1) {
        this.edit[currentlyExpanded].collapsed = false;
      }

      this.sideBar = {
        visible: true,
        itemsSelected: value.item,
        type,
        title,
        action: value.action,
      };
    },

    close() {
      this.sideBar = {
        visible: false,
        itemsSelected: null,
        type: null,
        title: null,
        action: null,
      };
    },

    expandMenu(index) {
      this.close();
      const currentlyExpanded = this.edit.findIndex((item) => item.collapsed);
      if (currentlyExpanded !== -1 && currentlyExpanded !== index) {
        this.edit[currentlyExpanded].collapsed = false;
      }
      this.edit[index].collapsed = !this.edit[index].collapsed;
    },

    getComponentForName(name) {
      const componentMap = {
        Header: "header-list",
        Sidebar: "sidebar-list",
        Ads: "ads-list",
        Categories: "category-list",
        "Action Cards": "action-cards-list",
        Footer: "footer-list",
        Login: "login",
        Register: "register",
        Lobby: "lobby-list",
      };
      return componentMap[name] || null;
    },

    getTypeForName(name) {
      return name.toLowerCase();
    },

    getSideBarComponent() {
      const componentMap = {
        header: PEditHeader,
        sidebar: PEditSidebar,
        ads: PEditAds,
        categories: PECategory,
        "action cards": PEditActionCard,
        footer: PEFooter,
        login: PEEditLogin,
        register: PEEditRegister,
        lobby: PEditLobby,
      };
      return componentMap[this.sideBar.type];
    },
  },

  computed: {
    loading() {
      return store.state["app-whitelabel-templates"].loadingUpdate;
    },
  },
};
</script>

<template>
  <div>
    <b-sidebar
      :visible="sideBar.visible"
      @hidden="close"
      :title="sideBar.title"
      right
    >
      <div class="px-1 py-2">
        <b-overlay
          :show="loading"
          variant="transparent"
          rounded
          spinner-variant="primary"
          spinner-type="grow"
          blur="50px"
        >
          <component
              :is="getSideBarComponent()"
              v-if="sideBar.type"
              @reset="close"
              @created="close"
              @updated="close"
              :options="sideBar"
          />
        </b-overlay>
      </div>
    </b-sidebar>

    <!--  <transition name="slide-x" appear>-->
    <div class="card template-card">
      <div class="mb-1">v2</div>

      <b-overlay
        :show="loading"
        variant="transparent"
        rounded
        spinner-variant="primary"
        spinner-type="grow"
        blur="50px"
      >
        <ul
          class="d-flex nav flex-column ds-btn"
          style="padding: 0; list-style: none"
        >
          <li v-for="(data, index) in edit" :key="index" class="w-100 nav-item">
            <div
              class="w-100 mb-1 text-left cursor-pointer"
              style="background: none !important; border: none !important"
              @click.exact="expandMenu(index)"
            >
              <span>{{ $t(data.name) }}</span>
              <span class="float-right">
                <feather-icon
                  :icon="data.collapsed ? 'ChevronUpIcon' : 'ChevronRightIcon'"
                  size="16"
                />
              </span>
            </div>

            <b-collapse :id="'collapse-' + index" v-model="data.collapsed">
              <component
                v-if="data.collapsed"
                :is="getComponentForName(data.name)"
                class="mb-1"
                @updateOrCreate="
                  onUpdateOrCreate(
                    $event,
                    getTypeForName(data.name),
                    $t(data.name)
                  )
                "
              ></component>
            </b-collapse>
          </li>
        </ul>
      </b-overlay>
    </div>
    <!--  </transition>-->
  </div>
</template>

<style scoped lang="scss">
.template-card {
  min-width: 200px;
  max-height: 70vh;
  overflow-y: auto;
  min-height: 200px;
  position: absolute;
  background: white;
  top: 15px;
  left: 70px;
  padding: 12px;
  border-radius: 10px;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

.dark-layout {
  .card {
    background: #283046 !important;
  }

  .nav-link {
    color: #fff !important;
  }
}

.slide-x-enter-active,
.slide-x-leave-active {
  transition: all 0.25s ease;
}

.slide-x-enter {
  transform: translateX(-15%);
}

.slide-x-leave-to {
  opacity: 0;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.2s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
}

.expand-enter-to,
.expand-leave {
  max-height: 1000px; /* Ajusta este valor según el contenido máximo esperado */
}
</style>
