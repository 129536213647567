import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import { computed, ref, watch } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";

export default function useActionCardList() {
  const MODULE_KB_STORE_NAME = "kb-whitelabel-templates";

  // Register module
  if (!store.hasModule(MODULE_KB_STORE_NAME))
    store.registerModule(MODULE_KB_STORE_NAME, whitelabelTemplatesModule);

  const toast = useToast();
  const refActionCardListTable = ref(null);
  const loading = ref(false);
  const perPage = ref(10);
  const totalActionCards = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(false);
  const recordDeleted = ref(false);

  const editTemplate = computed(
    () => store.state["kb-whitelabel-templates"].editTemplate
  );

  const dataMeta = computed(() => {
    const localItemsCount =
      refActionCardListTable.value?.localItems.length || 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalActionCards.value,
    };
  });

  const refetchData = () => {
    refActionCardListTable.value?.refresh();
  };

  const isSelected = (id) =>
    editTemplate.value.templateData?.call_to_action_card._id === id;

  const findSelected = (id) => {
    return (
      refActionCardListTable.value?.localItems.find(
        (item) => item._id === id
      ) || null
    );
  };

  const selectActionCard = async (id) => {
    const selected = findSelected(id);

    if (!selected) return;

    const updatedTemplate = {
      ...editTemplate.value,
      templateData: {
        ...editTemplate.value.templateData,
        call_to_action_card: selected
      }
    };
    store.commit("kb-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);

    try {
      await store.dispatch("kb-whitelabel-templates/updateTemplate", {
        template: updatedTemplate,
        toast,
        path: 'templateData.call_to_action_card'
      });
    } catch (error) {
      showErrorToast(toast,"Error updating template", axiosErrorHandle(error));
    }
  };

  const fetchActionCards = (ctx, callback) => {
    loading.value = true;

    store
      .dispatch("kb-action-card/fetchActionCards", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
        templateId: editTemplate.value._id,
      })
      .then((response) => {
        loading.value = false;
        const { total, response: call_to_action_cards } = response.data;
        callback(call_to_action_cards);
        totalActionCards.value = total;
      })
      .catch((error) => {
        loading.value = false;
        showErrorToast(
            toast,
          "Error fetching action cards list",
          axiosErrorHandle(error)
        );
      });
  };

  watch([currentPage, perPage, searchQuery, recordDeleted], () => {
    refetchData();
  });

  return {
    isSelected,
    fetchActionCards,
    perPage,
    currentPage,
    totalActionCards,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refActionCardListTable,
    refetchData,
    recordDeleted,
    loading,
    selectActionCard,
  };
}
