import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchActionCards(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/callActionCards`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchHeader(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/headers/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addActionCard(ctx, addActionCardData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/callActionCards`,  addActionCardData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateActionCard(ctx, { id, actionCardData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/callActionCards/${id}`, actionCardData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchHeaderIamge(ctx,  id ) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/files/${id}?path=headers`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeActionCard(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/callActionCards/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
