<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import actionCardStoreModule from "./store/actionCardStoreModule";
import i18n from "@/libs/i18n";
import { RequestBuilder } from "@core/utils/requestBuilder";
import vSelect from "vue-select";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";

export default {
  name: "index",
  components: {
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    vSelect,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "actionCards",
        visible: false,
      }),
    },
  },

  setup(props, { emit }) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const ACTION_CARD_KB_STORE_MODULE_NAME = "kb-action-card-store";
    const MODULE_KB_STORE_NAME = "kb-store";

    // Register module only if not already registered
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(ACTION_CARD_KB_STORE_MODULE_NAME))
      store.registerModule(
        ACTION_CARD_KB_STORE_MODULE_NAME,
        actionCardStoreModule
      );
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    const actionCard = ref(null);
    const loading = ref(false);

    const rBuild = RequestBuilder();
    const toast = useToast();

    onMounted(() => {
      resetActionCard();
      const { itemsSelected } = props.options;
      if (itemsSelected) actionCard.value = itemsSelected;
      updatePreview();
    });

    const editTemplate = computed(
      () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const isEditMode = computed(() => props.options.action === "edit");

    const resetActionCard = () => {
      actionCard.value = {
        name: "",
        title: "",
        subtitle: "",
        cta_button: {
          title: "",
          href: "",
        },
        left_top_images: [],
        left_bottom_images: [],
        back_images: [],
      };
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateActionCard();
      } else {
        createActionCard();
      }
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
          "app-whitelabel-templates/updateTemplate",
          {
            template: editTemplate.value,
            toast,
            path: 'templateData.call_to_action_card'
          }
        );

      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_template"),
          axiosErrorHandle(error)
        );
      }
    };

    const createActionCard = async () => {
      loading.value = true;
      actionCard.value.whitelabelId =
        store.state.whitelabelCurrencyNabvar.whitelabel._id;
      actionCard.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
          "kb-action-card-store/addActionCard",
          rBuild.clean(actionCard.value)
        );

        showSuccessToast(toast, "Action Card", "Action Card created");
        updatePreview(response.data);
        resetAll();
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_update_action_card"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateActionCard = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          "kb-action-card-store/updateActionCard",
          {
            id: actionCard.value._id,
            actionCardData: rBuild.clean(actionCard.value),
          }
        );

        showSuccessToast(toast, "Action Card", i18n.t("action_card_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_update_action_card"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetAll = () => {
      store.commit("kb-store/SET_CLICKED_ACTION_CARD", null);
    };

    // watch(
    //   () => store.state["kb-store"].clickedActionCard,
    //   (val) => {
    //     if (val && val._id) {
    //       actionCard.value = val;
    //     }
    //   },
    //   { deep: true }
    // );

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      () => {}
    );

    const onUploadedImage = (data, key) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        if (key === "left_top_images" && actionCard.value[key].length === 4) {
          return maximumAllowedImages();
        } else if (
          key === "left_bottom_images" &&
          actionCard.value[key].length === 3
        ) {
          return maximumAllowedImages();
        }
        actionCard.value[key].push(image);
        updatePreview();
      }
    };

    const onUploadedBackImages = (data, key) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        if (key === "back_images" && actionCard.value[key].length === 2) {
          return maximumAllowedImages();
        }
        actionCard.value[key].push(image);
        updatePreview();
      }
    };

    const maximumAllowedImages = () => {
      showErrorToast(
        toast,
        i18n.t("error_loading_image"),
        "maximum allowed images reached"
      );
    };

    const unListImage = (index, key) => {
      if (index >= 0 && index < actionCard.value[key].length) {
        actionCard.value[key].splice(index, 1);
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.call_to_action_card");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          call_to_action_card: value || actionCard.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetActionCard,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      onUploadedImage,
      onUploadedBackImages,
      unListImage,
      errorInLoadImage,
      loading,
      actionCard,
      resetData,
      updatePreview,
    };
  },
};
</script>

<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        v-if="actionCard"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-list-group-item style="background: none" class="px-0">
          <b-row>
            <validation-provider
              class="w-100"
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="c-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                    id="c-name"
                    type="text"
                    v-model="actionCard.name"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="acTitle"
                rules="required"
              >
                <b-form-group label="title" label-for="c-title">
                  <template #label>{{ $t("labels.title") }}</template>
                  <b-form-input
                    @input="updatePreview()"
                    id="c-title"
                    type="text"
                    v-model="actionCard.title"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="subtitle"
                rules="required"
              >
                <b-form-group label="Subtitle" label-for="c-subtitle">
                  <template #label>{{ $t("subtitle") }}</template>
                  <b-form-textarea
                    @input="updatePreview()"
                    id="c-subtitle"
                    rows="3"
                    v-model="actionCard.subtitle"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="title"
                rules="required"
              >
                <b-form-group label="title" label-for="cta-title">
                  <template #label>{{ $t("title") }}</template>
                  <b-form-input
                    @input="updatePreview()"
                    id="cta-title"
                    type="text"
                    v-model="actionCard.cta_button.title"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="href"
                rules="required"
              >
                <b-form-group label="href" label-for="cta-href">
                  <template #label>{{ $t("labels.link") }}</template>
                  <b-form-input
                    id="cta-href"
                    type="text"
                    v-model="actionCard.cta_button.href"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('left_top_images')" label-for="c-image">
                <span>Maximum 4 images: 168x68</span>
                <image-field-form
                  :single-button="true"
                  size-validation="168x68"
                  :text="$t('labels.image')"
                  class="w-100 mt-1"
                  path="actionCards"
                  @error="errorInLoadImage"
                  @uploadedImage="onUploadedImage($event, 'left_top_images')"
                >
                </image-field-form>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div
              v-for="(image, index) in actionCard.left_top_images"
              :key="index"
              class="col image-container"
            >
              <b-button
                @click="unListImage(index, 'left_top_images')"
                variant="primary"
                class="btn-icon rounded-circle close-button mr-1"
                size="sm"
              >
                <feather-icon :icon="'TrashIcon'" />
              </b-button>
              <b-img class="rounded" fluid :src="image" alt=""></b-img>
            </div>
          </div>

          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('left_bottom_images')"
                label-for="c-image"
              >
                <span>Maximum 3 images: 168x68</span>
                <image-field-form
                  :single-button="true"
                  size-validation="168x68"
                  :text="$t('labels.image')"
                  class="w-100 mt-1"
                  path="actionCards"
                  @error="errorInLoadImage"
                  @uploadedImage="onUploadedImage($event, 'left_bottom_images')"
                >
                </image-field-form>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div
              v-for="(image, index) in actionCard.left_bottom_images"
              :key="index"
              class="col image-container"
            >
              <b-button
                @click="unListImage(index, 'left_bottom_images')"
                variant="primary"
                class="btn-icon rounded-circle close-button mr-1"
                size="sm"
              >
                <feather-icon :icon="'TrashIcon'" />
              </b-button>
              <b-img class="rounded" fluid :src="image" alt=""></b-img>
            </div>
          </div>

          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('back_images')" label-for="c-image">
                <span>756x754</span>
                <image-field-form
                  :single-button="true"
                  size-validation="756x754"
                  :text="$t('labels.image')"
                  class="w-100 mt-1"
                  path="actionCards"
                  @error="errorInLoadImage"
                  @uploadedImage="
                    onUploadedBackImages($event, 'back_images', 1)
                  "
                >
                </image-field-form>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group :label="$t('back_images')" label-for="c-image">
                <span>358x359</span>
                <image-field-form
                  :class="{ 'pointer-events-none': !actionCard.back_images[0] }"
                  :single-button="true"
                  size-validation="358x359"
                  :text="$t('labels.image')"
                  class="w-100 mt-1"
                  path="actionCards"
                  @error="errorInLoadImage"
                  @uploadedImage="
                    onUploadedBackImages($event, 'back_images', 2)
                  "
                >
                </image-field-form>
                <p class="pt-1 text-danger" v-if="!actionCard.back_images[0]">
                  Enter the first "Back Image"
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div
              v-for="(image, index) in actionCard.back_images"
              :key="index"
              class="col image-container"
            >
              <b-button
                @click="unListImage(index, 'back_images')"
                variant="primary"
                class="btn-icon rounded-circle close-button mr-1"
                size="sm"
              >
                <feather-icon :icon="'TrashIcon'" />
              </b-button>
              <b-img class="rounded" fluid :src="image" alt=""></b-img>
            </div>
          </div>
        </b-list-group-item>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
            :disabled="loading || invalid"
            size="md"
            type="submit"
            class="w-100"
            variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<style scoped lang="scss">
.image-container {
  height: 70px;
  width: 100px;

  .close-button {
    left: 40%;
    top: 50%;
    position: absolute !important;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  &:hover {
    .close-button {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
